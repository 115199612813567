import { CTAProps, TagProps } from 'components/Card/types';
import { PriceConfigProps } from 'components/SearchPropertyCards/types';
import { Image } from './app';

interface PriceHistoryProps {
  date: string;
  changes: {
    previousValue: string;
    currentValue: string;
  };
}

export type AgentType = {
  name: string;
  telephone?: string;
  email: string;
  href?: string;
  image: string;
  smallImage?: string;
  workPhone?: string;
  mobilePhone?: string;
  profileImageUrl?: string;
};

export enum MarketingPortals {
  SAVILLS = 'savillsWebsite',
  RIGHTMOVE = 'rightmove',
  ZOOPLA = 'zoopla',
}

export interface Property {
  reapitId: string;
  externalPropertyId: string;
  mainPrice?: string;
  acceptedPrice?: string;
  guidePrice?: string;
  geoLocation: google.maps.LatLngLiteral;
  sellingStatus?: SellingStatus;
  lettingStatus?: LettingStatus;
  qualifier?: string;
  area?: Area;
  internalArea?: string;
  externalArea?: string;
  address: Address;
  bedrooms: number;
  bathrooms: number;
  receptions: number;
  tenure?: Tenure;
  type: PropertyType;
  images: Image[];
  floorplans?: Image[];
  tags?: TagProps[];
  negotiator?: Negotiator;
  savillsWebsiteUrl?: string;
  rent?: number;
  availableFrom?: string;
  availableTo?: string;
  agentRole?: string;
  rentFrequency?: string;
  instructionRequested?: boolean;
  summary?: string[];
  internetAdvertising?: boolean;
  isCommercial?: boolean;
  isNewDevelopment?: boolean;
  isFarm?: boolean;
  priceConfig?: PriceConfigProps;
  featureList?: string[];
  countryId?: string;
  officeName: string;
  propertyTypes: string[];
  priceHistory: PriceHistoryProps[];
  isScottishProperty?: boolean;
  isGDPRProperty?: boolean;
  closingDate?: string;
  isParent?: boolean;
  newDevelopmentBedrooms?: string;
  newDevelopmentBathrooms?: string;
  newDevelopmentReceptions?: string;
  agents?: Agent[];
  labels: Label[];
  officeGrade?: string;
  officePeople?: string;
  marketingPortals?: MarketingPortals[];
  isManaged?: boolean;
}

export interface PropertyAddress {
  id: string;
  address: string;
}

export enum SellingStatus {
  PreAppraisal = 'preAppraisal',
  ForSale = 'forSale',
  ForSaleUnavailable = 'forSaleUnavailable',
  ForSaleLostMarket = 'forSaleLostMA',
  UnderOffer = 'underOffer',
  UnderOfferUnavailable = 'underOfferUnavailable',
  Exchanged = 'exchanged',
  SoldExternally = 'soldExternally',
  Withdrawn = 'withdrawn',
  Completed = 'completed',
  Valuation = 'valuation',
  PaidValuation = 'paidValuation',
  Reserved = 'reserved',
}

export const enum LettingStatus {
  ChangeOfSharer = 'ChangeOfSharer',
  OnTheMarketFirstTime = 'OnTheMarketFirstTime',
  OnTheMarketReLet = 'OnTheMarketReLet',
  PendingOffer = 'PendingOffer',
  ArrangingTenancy = 'Arranging',
  ActiveTenancy = 'Active',
  TenancyAgreed = 'TenancyAgreed',
  Complete = 'Complete',
  Removed = 'Removed',
  Withdrawn = 'Withdrawn',
  BookValuation = 'BookValuation',
  Empty = 'Empty',
  MarketAppraisal = 'MarketAppraisal',
  Invalid = 'Invalid',
  Hidden = 'Hidden',
  ActiveEndNear = 'ActiveEndNear',
  ActiveTenancyWithRenewal = 'ActiveWithRenewal',
  EndOfTenancyConfirmed = 'EndOfTenancyConfirmed',
  ActiveTenancyChangeOfSharer = 'ActiveChangeOfSharer',
  DepositRelease = 'EndConfirmed',
  DeterminedByTenancies = 'DeterminedByTenancies',
}

export enum PropertyAttribute {
  Bedrooms = 'Bedrooms',
  Receptions = 'Receptions',
  Bathrooms = 'Bathrooms',
  OfficeGrade = 'OfficeGrade',
  OfficePeople = 'OfficePeople',
}

export type Address = {
  line1: string;
  line2: string;
  postcode?: string;
  buildingNumber?: string;
  buildingName?: string;
};

export enum Tenure {
  Buy = 'Buy',
  Rent = 'Rent',
}

export type Price = {
  currency: string;
  displayCurrency: string;
  min: number;
  max: number;
};

export type PropertySize = {
  measurement: string;
  min: number;
  max: number;
};

export type SearchArea = {
  coordinates: number[];
  locationId: string;
  radius: string;
  type: string;
};

export type Area = {
  size: string;
  type: AreaType;
};
export enum LabelType {
  Neutral = 'neutral',
  Alert = 'alert',
  NewDevelopment = 'new-development',
  Farm = 'farm',
  Commercial = 'commercial',
  Default = 'default',
  SharedOwnership = 'shared-ownership',
  MarketType = 'market-type',
}

export enum AreaType {
  PropertySize = 'PropertySize',
  LandSize = 'LandSize',
}

export enum PropertyType {
  House = 'house',
  Bungalow = 'bungalow',
  FlatApartment = 'flat-apartment',
  Maisonette = 'maisonette',
  Land = 'land',
  Farm = 'farm',
  Cottage = 'cottage',
  Studio = 'studio',
  Townhouse = 'townhouse',
  DevelopmentPlot = 'development-plot',
  NewDevelopment = 'new-development',
  Duplex = 'duplex',
  Penthouse = 'penthouse',
  Vineyard = 'vineyard',
  Retail = 'retail',
  BuildingPlot = 'building-plot',
  Chalet = 'chalet',
  Compound = 'compound',
  Condominium = 'condominium',
  CountryHouse = 'country-house',
  ExecutiveCondominium = 'executive-condominium',
  FlatOutsideCity = 'flat-outside-city',
  HDBApartment = 'hdb-apartment',
  HighRiseBuilding = 'high-rise-building',
  MiddleRiseBuilding = 'middle-rise-building',
  ServicedApartment = 'serviced-apartment',
  TemporaryUseApartments = 'temporary-use-apartments',
  Triplex = 'triplex',
  Villa = 'villa',
  Castle = 'castle',
  Estate = 'estate',
  Equestrian = 'equestrian',
  Mews = 'mews',
  DevelopmentLand = 'development-land',
  Development = 'development',
  Industrial = 'industrial',
  Leisure = 'leisure',
  Office = 'office',
  Hotel = 'hotel',
  Heathcare = 'heathcare',
  Investment = 'investment',
  ServicedOffice = 'serviced-office',
  Sporting = 'sporting',
  Woodland = 'woodland',
  ArableFarm = 'arable-farm',
  BareLand = 'bare-land',
  DairyFarm = 'dairy-farm',
  LivestockFarm = 'livestock-farm',
  ResidentialFarmEstate = 'residential-farm-estate',
  Laboratory = 'laboratory',
  Healthcare = 'healthcare',
  BusinessPark = 'business-park',
  FlexSpace = 'flex-space',
  SeniorLiving = 'senior-living',
}

type Negotiator = {
  id: string;
  email: string;
  workPhone?: string;
  mobilePhone: string;
  name: string;
  profileImageUrl: string;
  officeId?: string;
};

export type Agent = {
  id: string;
  name: string;
  phoneNumber: string;
  email: string;
  profileImageUrl: string;
  office: string;
  isPrimary: boolean;
};

type Label = {
  type?: string;
  value?: string;
  resource?: string;
};

export type PropertyOffers = {
  id: string;
};

export enum AppointmentCodes {
  AP = 'AP',
  CD = 'CD',
  CI = 'CI',
  CO = 'CO',
  ES = 'ES',
  FA = 'FA',
  HV = 'HV',
  HY = 'HY',
  IA = 'IA',
  MA = 'MA',
  ME = 'ME',
  MI = 'MI',
  OH = 'OH',
  OV = 'OV',
  PI = 'PI',
  PL = 'PL',
  RV = 'RV',
  RR = 'RR',
  SY = 'SY',
  VL = 'VL',
  VW = 'VW',
  CRTEXP = 'CRTEXP',
  LICEXP = 'LICEXP',
  TENMVOUT = 'TENMVOUT',
  TENMVIN = 'TENMVIN',
  BKCLAUSE = 'TENBRKCLS',
  TENSTRT = 'TENSTRT',
  TENEND = 'TENEND',
  RENTDUE = 'RENTDUE',
}

export type Appointment = {
  reapitId: string;
  propertyId: string;
  typeId: string;
  start: string;
  end: string;
  associatedOffices: string[];
  description: string;
  accompaniedByNeg: boolean;
  isCancelled: boolean;
  isConfirmed: boolean;
  isRecurring: boolean;
  isVirtual: boolean;
  negotiatorIds: string[];
  negotiators: {
    id: string;
    name: string;
    workPhone: string;
    email: string;
  }[];
  followUp: {
    due: string;
    notes: string;
  };
  applicant: {
    name: string;
  };
  ctas?: CTAProps[];
  allDay?: boolean;
  showCta?: boolean;
};

export enum PropertyViewTypes {
  VENDOR = 'Vendor',
  LANDLORD = 'Landlord',
}

export enum PropertyPageComponents {
  DOCUMENTS = 'documents',
  FEEDBACK = 'feedback',
  NEGOTIATOR_CARD = 'negotiatorCard',
  MARKETING_STATS = 'marketingStats',
  CALENDAR = 'calendar',
  TASKS = 'tasks',
  OFFERS = 'offers',
  INSTRUCT = 'instruct',
  AGENT = 'agent',
  PROPERTY_INFO = 'propertyInfo',
  DEAL_PROGRESS = 'dealProgress',
  EVENTS = 'events',
  TEAM_CONTACT = 'teamContact',
  TENANT_CONTACT = 'tenantContact',
  RECENTLY_LET_PROPERTIES = 'recentlyLetProperties',
  RESOURCES = 'resources',
  IN_TENANCY_TIMELINE = 'inTenancyTimeline',
  TIMELINE = 'timeline',
  RENEWAL_PROGRESS = 'renewalProgress',
  TENANCY_ENDING_PROGRESS = 'tenancyEndingProgress',
  STATUS_PROGRESS = 'statusProgress',
  ARRANGING_TENANCY_PROGRESS = 'arrangingTenancyProgress',
  ARCHIVED_DOCUMENTS = 'archivedDocuments',
}

export enum PropertyCardTag {
  Commercial = 'Commercial',
  NewDevelopment = 'NewDevelopment',
  Farm = 'Farm',
  Residential = 'Residential',
  SharedOwnership = 'SharedOwnership',
  AvailableInLots = 'AvailableInLots',
  CommercialScheme = 'CommercialScheme',
  RetailScheme = 'RetailScheme',
  FeaturedProperties = 'FeaturedProperties',
}

export type PortfolioTenancy = {
  tenancyId: string;
  status: LettingStatus;
  link?: string;
  isCurrent: boolean;
};
export type PortfolioProperty = {
  propertyId: string;
  companyId?: string;
  companyName?: string;
  lettingType: string;
  status: LettingStatus;
  address: Address;
  link?: string;
  tenancies: PortfolioTenancy[];
};
export interface PortfolioProperties {
  personalProperties: PortfolioProperty[];
  companyProperties: PortfolioProperty[];
}

export enum PortfolioTypes {
  PERSONAL = 'personal',
  COMPANY = 'company',
}

export type SidebarPortfolioProperty = {
  propertyId: string;
  address?: Address;
  link?: string;
  tenancies?: PortfolioTenancy[];
};

export type PortfolioPropertyFormatted = {
  title: string;
  type: PortfolioTypes;
  link?: string;
  properties: SidebarPortfolioProperty[];
};

export type CompanyPortfolio = {
  companyId: string;
  companyName: string;
  link?: string;
};
