/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
import React, { lazy, Suspense, useEffect, useContext } from 'react';
import moment from 'moment';
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import { ModalContextProvider } from 'savills-ui-kit';
import { useAuth } from 'react-oidc-context';

import './services/logout';

import { ROUTES } from 'constants/routes';
import { AppGrid, Main } from 'components/Layout/Layout.styled';

import { ErrorStates } from 'views/Error/types';
import { Sidebar } from 'components/Sidebar/Sidebar';
import { useAppData } from 'hooks/useAppData';
import { FatalErrorHandlerContext } from 'providers/FatalError/FatalErrorHandler';
import { AccountStateContextProvider } from 'providers/Account/AccountState';
import Loading from 'components/Loading/Loading';
import appT9nQuery from 'queries/t9n/appT9n.gql';
import { T9nContext, withT9nHook } from 'providers/T9nProvider/t9nContext';
import { PropertyViewTypes } from 'types/property';
import { PageTitleContext } from 'providers/PageTitle/PageTitleContext';
import { useGoogleAnalytics } from 'providers/GoogleAnalytics/GoogleAnalyticsContext';
import { FeatureFlags } from 'utils/helpers/detection/types';
import { DEFAULT_LOCALE_PARAMS } from 'constants/global';
import ViewingsView from 'views/Applicant/ApplicantViews/ViewingsView';
import OffersView from 'views/Applicant/ApplicantViews/OffersView';
import UnavailablePreviewPage from 'views/Notice/UnavailablePreviewPage';
import { ReapitMaintenanceNotice } from 'components/Maintenance/ReapitMaintenanceMode';
import Footer from 'components/Footer/Footer';
import { DocumentFilterProvider } from 'providers/Documents/DocumentFilterContext';
import { HeaderFooterContext } from 'providers/HeaderFooter/HeaderFooterContext';
import { checkIfEnabled } from 'helpers/helpers';
import { NotificationFeature } from 'components/NotificationBanner/types';
import NotificationBannerComponent from 'components/NotificationBanner/NotificationBanner';

moment.updateLocale(DEFAULT_LOCALE_PARAMS.LANGUAGE, {
  week: {
    dow: 1,
  },
});
const VendorPage = lazy(() => import('views/Vendor/VendorPage'));
const ApplicantPage = lazy(() => import('views/Applicant/Applicant'));
const ApplicantHomeView = lazy(
  () => import('views/Applicant/ApplicantViews/ApplicantHome'),
);
const SavedPropertiesPage = lazy(
  () => import('views/Applicant/ApplicantViews/SavedPropertiesView'),
);
const AccountPage = lazy(() => import('views/Account/AccountPage'));
const DocumentsPage = lazy(
  () => import('views/Documents/DocumentsPage/DocumentsPage'),
);
const ErrorPage = lazy(() => import('views/Error/ErrorPage'));
const FatalErrorPage = lazy(() => import('views/Error/FatalErrorPage'));
const SellingProperty = lazy(
  () => import('views/Vendor/SellingProperty/SellingProperty'),
);
const UploadDocumentPage = lazy(
  () => import('views/Documents/UploadDocument/UploadDocument'),
);
const FeedbackPage = lazy(() => import('components/Feedback/FeedbackPage'));
const LandlordPage = lazy(() => import('views/Landlord/LandlordPage'));
const PortfolioPage = lazy(() => import('views/Landlord/PortfolioPage'));
const LandlordMarketingPage = lazy(
  () => import('views/Landlord/MarketingPage'),
);

interface AppProps {
  readonly languageCode: string;
  readonly countryCode: string;
  readonly isPreview?: boolean;
}

function App({ languageCode, countryCode, isPreview }: AppProps) {
  const auth = useAuth();
  const notification = useFeatureValue<NotificationFeature | null>(
    'notification-banner',
    null,
  );
  const { t9n } = useContext(T9nContext);
  const location = useLocation(); // Causes entire app rerender every navigation.
  const navigate = useNavigate(); // Causes entire app rerender every navigation.
  const { pageTitle } = useContext(PageTitleContext);
  const { setRouteChangeEvent } = useGoogleAnalytics();
  const { footerData, headerData } = useContext(HeaderFooterContext);
  const showApplicant = useFeatureIsOn(FeatureFlags.APPLICANT as string);
  const isReapitMaintenanceMode = useFeatureIsOn(
    FeatureFlags.REAPIT_MAINTENANCE_MODE as string,
  );

  const initialState = { auth };
  const { propertyList, isLoading, tasks, documentList, profile } =
    useAppData();

  const { fatalError } = useContext(FatalErrorHandlerContext);

  useEffect(() => {
    if (!pageTitle) {
      return;
    }
    setRouteChangeEvent({
      event: 'routeChangeManual',
      path: location.pathname,
      pageTitle: pageTitle || '',
      pageLanguage: languageCode,
      pageCountry: countryCode,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTitle, setRouteChangeEvent]);

  const isUserConnected = checkIfEnabled(auth);

  useEffect(() => {
    const selling = propertyList?.selling || [];
    const letting = propertyList?.letting || [];
    const isHomeRoute = location.pathname === ROUTES.HOME;

    switch (true) {
      case isLoading || fatalError:
        return undefined;
      case selling.length > 0 && isHomeRoute && isUserConnected:
        return navigate(`${ROUTES.VENDORS.ROOT}/${selling[0].id}`);

      case letting.length > 0 && isHomeRoute && isUserConnected:
        return navigate(`${ROUTES.LETTING.ROOT}/${letting[0].id}`);

      case isHomeRoute && showApplicant:
        return navigate(ROUTES.APPLICANT.ROOT);

      case selling.length === 0 && !showApplicant && isHomeRoute:
        return navigate(`${ROUTES.NO_PROPERTIES}`);

      default:
        return undefined;
    }
  }, [
    navigate,
    location.pathname,
    propertyList,
    showApplicant,
    isLoading,
    fatalError,
  ]);

  useEffect(() => {
    if (auth.isLoading || !auth?.user) return () => null;
    return auth.events.addSilentRenewError(() => {
      // eslint-disable-next-line no-console
      console.log('failed to renew token');
      auth.signinRedirect();
    });
  }, [auth.isLoading, auth.events, auth.signinSilent]);

  const showUKSellingPage = countryCode === DEFAULT_LOCALE_PARAMS.COUNTRY;

  if ((auth.isLoading || isLoading) && !fatalError) {
    return <Loading />;
  }

  return (
    <AccountStateContextProvider
      isPreview={isPreview}
      initialState={initialState}
    >
      <ModalContextProvider>
        {fatalError ? (
          <FatalErrorPage error={fatalError} />
        ) : (
          <AppGrid>
            <Sidebar
              propertyList={propertyList}
              isUserConnected={isUserConnected}
              isPreview={isPreview}
            />
            <Main>
              <NotificationBannerComponent
                t9n={t9n}
                auth={auth}
                notification={notification}
              />
              <Suspense fallback={<Loading />}>
                <Routes>
                  <Route
                    path={ROUTES.BUCKET_INDEX}
                    element={<Navigate to={ROUTES.HOME} replace />}
                  />
                  <Route
                    path={ROUTES.APPLICANT.ROOT}
                    element={<ApplicantPage profile={profile} />}
                  >
                    <Route index element={<ApplicantHomeView />} />
                    <Route
                      path={ROUTES.APPLICANT.SAVED_PROPERTIES}
                      element={<SavedPropertiesPage />}
                    />
                    <Route
                      path={ROUTES.APPLICANT.VIEWINGS}
                      element={<ViewingsView />}
                    />
                    <Route
                      path={ROUTES.APPLICANT.OFFERS}
                      element={<OffersView />}
                    />
                  </Route>
                  {showUKSellingPage && (
                    <Route
                      path={ROUTES.NO_PROPERTIES}
                      element={
                        isUserConnected && isReapitMaintenanceMode ? (
                          <ReapitMaintenanceNotice />
                        ) : (
                          <SellingProperty />
                        )
                      }
                    />
                  )}
                  <Route
                    path={`${ROUTES.VENDORS.ROOT}/:propertyId`}
                    element={
                      <VendorPage
                        documentTasks={tasks}
                        documents={documentList}
                        profile={profile}
                      />
                    }
                  />
                  <Route
                    path={`${ROUTES.VENDORS.FEEDBACK}/:propertyId`}
                    element={
                      <FeedbackPage
                        type={PropertyViewTypes.VENDOR}
                        profile={profile}
                      />
                    }
                  />
                  <Route
                    path={`${ROUTES.LETTING.FEEDBACK}/:propertyId`}
                    element={
                      <FeedbackPage
                        type={PropertyViewTypes.LANDLORD}
                        profile={profile}
                      />
                    }
                  />
                  {isUserConnected && (
                    <Route
                      path={ROUTES.DOCUMENTS.ROOT}
                      element={
                        <DocumentFilterProvider>
                          <DocumentsPage
                            propertyList={propertyList || []}
                            documentList={documentList || []}
                          />
                        </DocumentFilterProvider>
                      }
                    />
                  )}
                  <Route
                    path={ROUTES.DOCUMENTS.UPLOAD}
                    element={
                      <UploadDocumentPage propertyList={propertyList || []} />
                    }
                  />
                  <Route
                    path={`${ROUTES.LETTING.ROOT}/:propertyId/:tenancyId`}
                    element={
                      <DocumentFilterProvider>
                        <LandlordPage
                          profile={profile}
                          documents={documentList}
                          documentTasks={tasks}
                          propertyList={propertyList || []}
                        />
                      </DocumentFilterProvider>
                    }
                  />
                  <Route
                    path={`${ROUTES.LETTING.ROOT}/:propertyId`}
                    element={
                      <DocumentFilterProvider>
                        <LandlordPage
                          profile={profile}
                          documents={documentList}
                          documentTasks={tasks}
                          propertyList={propertyList || []}
                        />
                      </DocumentFilterProvider>
                    }
                  />
                  <Route
                    path={`${ROUTES.LETTING.PORTFOLIO}/company/:portfolioId`}
                    element={<PortfolioPage />}
                  />
                  <Route
                    path={`${ROUTES.LETTING.PORTFOLIO}/:portfolioId`}
                    element={<PortfolioPage />}
                  />
                  <Route
                    path={`${ROUTES.LETTING.PORTFOLIO}`}
                    element={<PortfolioPage />}
                  />
                  <Route
                    path={`${ROUTES.LETTING.MARKETING}`}
                    element={<LandlordMarketingPage />}
                  />
                  <Route
                    path={ROUTES.ACCOUNT}
                    element={
                      isPreview ? (
                        <UnavailablePreviewPage
                          errorType={ErrorStates.UNAVAILABLE_PREVIEW}
                        />
                      ) : (
                        <AccountPage />
                      )
                    }
                  />
                  <Route
                    path={ROUTES.ERROR}
                    element={<ErrorPage errorType={ErrorStates.SERVER_ERROR} />}
                  />
                  <Route
                    path="*"
                    element={<ErrorPage errorType={ErrorStates.NOT_FOUND} />}
                  />
                </Routes>
              </Suspense>
              <ToastContainer />
            </Main>
            <Footer
              headerContent={headerData ?? undefined}
              footerContent={footerData ?? undefined}
            />
          </AppGrid>
        )}
      </ModalContextProvider>
    </AccountStateContextProvider>
  );
}

export default withT9nHook(App, appT9nQuery, ['PageTitles']);
