/* eslint-disable no-nested-ternary */
import { useQuery, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Profile, PropertyList } from 'types/app';
import { FeatureFlags } from 'utils/helpers/detection/types';
import { ReapitDocument } from 'views/Documents/types';
import { Task } from 'components/Tasks/types';
import appDataQuery from 'queries/appDataQuery.gql';
import maintenanceAppDataQuery from 'queries/maintenanceAppDataQuery.gql';
import documentsQuery from 'queries/documents/documentsQuery.gql';
import { getFilteredDocumentList } from 'utils/helpers/documents/documents';
import { useAuth } from 'react-oidc-context';
import { PortfolioProperties } from 'types/property';

const DEFAULT_PROPERTY_LIST = { selling: [], letting: [] };
const DEFAULT_PROFILE = {
  userId: '',
  viewingFeedbackVisible: false,
  landlordViewingFeedbackVisible: false,
};

const getFilteredPropertyList = (
  propertyList: PropertyList,
  showLettings: boolean,
) => {
  if (!propertyList) {
    return DEFAULT_PROPERTY_LIST;
  }

  if (showLettings) {
    return propertyList;
  }

  return { selling: propertyList.selling, letting: [] };
};

export const useAppData = () => {
  const auth = useAuth();

  const isLandlordEnabled =
    auth?.user?.profile?.my_savills_landlord_enabled === 'true';
  const isVendorEnabled =
    auth?.user?.profile?.my_savills_vendor_enabled === 'true';
  const showLettings =
    useFeatureIsOn(FeatureFlags.LETTINGS as string) && isLandlordEnabled;

  const isReapitMaintenanceMode = useFeatureIsOn(
    FeatureFlags.REAPIT_MAINTENANCE_MODE as string,
  );

  const [documentList, setDocumentList] = useState<ReapitDocument[]>([]);
  const [shouldReloadDocuments, reloadDocs] = useState<boolean>(false);
  const [tasks, setDocumentTasks] = useState<Task[]>([]);
  const [propertyLoading, setPropertyLoading] = useState<boolean>(true);
  const [propertyList, setPropertyList] = useState<PropertyList>(
    DEFAULT_PROPERTY_LIST,
  );
  const [portfolioProperties, setPortfolioProperties] =
    useState<PortfolioProperties>({
      personalProperties: [],
      companyProperties: [],
    });
  const [profile, setProfile] = useState<Profile>(DEFAULT_PROFILE);

  const appQuery = isReapitMaintenanceMode
    ? maintenanceAppDataQuery
    : appDataQuery;

  const { loading, data } = useQuery(appQuery, {
    errorPolicy: 'all',
    variables: {
      isLandlordEnabled,
    },
  });

  const [fetchDocuments, documentsData] = useLazyQuery<{
    documents: ReapitDocument[];
    documentTasks: Task[];
  }>(documentsQuery, {
    fetchPolicy: !shouldReloadDocuments ? 'cache-only' : 'cache-first',
  });

  useEffect(() => {
    if (shouldReloadDocuments && fetchDocuments) {
      fetchDocuments();
      reloadDocs(false);
    }
  }, [shouldReloadDocuments, showLettings, fetchDocuments]);

  useEffect(() => {
    if (data) {
      setDocumentList(
        getFilteredDocumentList(
          data.documents,
          showLettings,
          data.propertyList,
          isVendorEnabled,
        ),
      );
      setPropertyList(getFilteredPropertyList(data.propertyList, showLettings));
      setPropertyLoading(false);
      setDocumentTasks(data.documentTasks || []);
      setProfile(data.profile || DEFAULT_PROFILE);
      setPortfolioProperties(data.portfolioProperties);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, showLettings]);

  useEffect(() => {
    if (documentsData?.data?.documents) {
      setDocumentList(
        getFilteredDocumentList(
          documentsData.data.documents,
          showLettings,
          propertyList,
          isVendorEnabled,
        ),
      );
    }
  }, [documentsData, showLettings, propertyList, isVendorEnabled]);

  useEffect(() => {
    if (documentsData?.data?.documentTasks) {
      setDocumentTasks(documentsData.data.documentTasks);
    }
  }, [documentsData]);

  const refreshDocuments = () => {
    reloadDocs(true);
  };

  return {
    isLoading: loading || propertyLoading || documentsData.loading,
    documentList,
    propertyList,
    tasks,
    profile,
    refreshDocuments,
    isLandlordEnabled,
    isVendorEnabled,
    portfolioProperties,
  };
};
