import { T9nHubResource } from 'providers/T9nProvider/types/t9n';
import { LandlordStepCodes } from 'components/PropertyHeader/types';
import { Statuses } from 'components/Card/types';
import rentingCommercialBgImg from 'assets/leasing-a-commercial-property.jpg';
import lettingResidentialImg from 'assets/letting-residential.jpg';
import commercialImg from 'assets/commercial.jpg';
import newBuildBgImg from 'assets/newBuild.jpg';
import lettingInternationalImg from 'assets/letting-international-property.jpg';
import auctionImg from 'assets/signpostAuction.jpeg';
import sellingInternationalImg from 'assets/signpostInternational.jpeg';
import residentialImg from 'assets/signpostResidentialProperty.jpeg';
import ruralImg from 'assets/signpostRural.jpeg';

import { LettingStatus } from 'types/property';
import {
  LandlordMarketingStats,
  LandlordSignpostProps,
  LandlordSignpostTranslation,
  LandlordStatsProps,
  ManagedType,
} from './types';
import { LIVE_TENANCY_STATUSES } from './constants';

export const getStatusTags = (
  status: LandlordStepCodes | LettingStatus,
  type: ManagedType,
  isCurrentTenancy: boolean,
  t9n: T9nHubResource,
  isSideBar?: boolean,
) => {
  const tags = [];

  const isLiveTenancy =
    isCurrentTenancy || LIVE_TENANCY_STATUSES.includes(status);

  if (isLiveTenancy && isSideBar) {
    return [
      {
        title: t9n.Landlord.PropertyCard.Tags.LiveTenancy,
        status: Statuses.LIVE_TENANCY,
      },
    ];
  }

  switch (status) {
    case LandlordStepCodes.Appraisal:
    case LettingStatus.MarketAppraisal:
      tags.push({
        title: t9n.Landlord.PropertyCard.Tags.MarketAppraisal,
        status: Statuses.APPRAISAL,
      });
      break;
    case LandlordStepCodes.PendingOffer:
      tags.push({
        title: t9n.Landlord.PropertyCard.Tags.PendingOffer,
        status: Statuses.APPRAISAL,
      });
      break;
    case LandlordStepCodes.ArrangingTenancy:
    case LettingStatus.ArrangingTenancy:
      tags.push({
        title: t9n.Landlord.PropertyCard.Tags.Active,
        status: Statuses.APPRAISAL,
      });
      break;
    case LandlordStepCodes.OnTheMarket:
    case LettingStatus.OnTheMarketFirstTime:
    case LettingStatus.OnTheMarketReLet:
      tags.push({
        title: t9n.Landlord.PropertyCard.Tags.OnTheMarket,
        status: Statuses.APPRAISAL,
      });
      break;
    case LandlordStepCodes.Completed:
    case LettingStatus.Complete:
      tags.push({
        title: t9n.Landlord.PropertyCard.Tags.Completed,
        status: isSideBar ? Statuses.LIGHT_COMPLETE : Statuses.COMPLETE,
      });
      break;
    default:
      break;
  }

  if (isCurrentTenancy) {
    tags.push({
      title: t9n.Landlord.PropertyCard.Tags.LiveTenancy,
      status: Statuses.LIVE_TENANCY,
    });
  }

  if (type === ManagedType.MANAGED) {
    tags.push({
      title: t9n.Landlord.PropertyCard.Tags.Managed,
      status: Statuses.MANAGED,
    });
  }

  return tags;
};

export const getLettingSignpostItems = (
  t9n: T9nHubResource,
  signposts: { link: string; key: string }[],
) => {
  return t9n.LettingMarketing.Signposts.reduce(
    (
      accum: LandlordSignpostProps[],
      signPostTranslation: LandlordSignpostTranslation,
      i: number,
    ) => {
      const bgImgMappings: { [key: string]: string } = {
        sellingResidential: residentialImg,
        sellingCommercial: commercialImg,
        sellingFarm: ruralImg,
        lettingResidential: lettingResidentialImg,
        lettingCommercial: rentingCommercialBgImg,
        auction: auctionImg,
        newBuild: newBuildBgImg,
        internationalSelling: sellingInternationalImg,
        internationalLetting: lettingInternationalImg,
      };

      const bgImg = bgImgMappings[signposts[i].key];

      return [
        ...accum,
        {
          backgroundImg: bgImg,
          title: signPostTranslation.Title,
          handleClick: () => {
            return window.open(signposts[i].link);
          },
        },
      ];
    },
    [],
  );
};

export const getFormattedStats = (
  t9n: T9nHubResource,
  stats: LandlordStatsProps[],
) => {
  const statMappings = {
    [LandlordMarketingStats.Visits]: {
      title: t9n.LettingMarketing.VisitsTitle,
      body: t9n.LettingMarketing.VisitsBody,
      disclaimer: t9n.LettingMarketing.VisitsDisclaimer,
    },
    [LandlordMarketingStats.Experience]: {
      title: t9n.LettingMarketing.ExperienceTitle,
      body: t9n.LettingMarketing.ExperienceBody,
    },
    [LandlordMarketingStats.Coverage]: {
      title: t9n.LettingMarketing.CoverageTitle,
      body: t9n.LettingMarketing.CoverageBody,
    },
  };

  return stats.map((item) => {
    const mappedStat = statMappings[item.key];
    return mappedStat ? { ...item, ...mappedStat } : item;
  });
};
