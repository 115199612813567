import { TagProps } from 'components/Card/types';
import { ProgressIndicatorStep } from 'components/ProgressIndicator/types';
import { MarketingPortals, Property } from 'types/property';

export enum VendorStepCodes {
  Appraisal = 'Appraisal',
  Instructed = 'Instructed',
  OnTheMarket = 'OnTheMarket',
  UnderOffer = 'UnderOffer',
  Exchanged = 'Exchanged',
  Completed = 'Completed',
}

export enum VendorStatusCodes {
  PreAppraisalNotBooked = 'PreAppraisalNotBooked',
  PreAppraisal = 'PreAppraisal',
  Appraisal = 'Appraisal',
  PostAppraisal = 'PostAppraisal',
  Instructed = 'Instructed',
  OnTheMarket = 'OnTheMarket',
  UnderOffer = 'UnderOffer',
  Exchanged = 'Exchanged',
  Completed = 'Completed',
}

export enum LandlordStepCodes {
  Appraisal = 'Appraisal',
  OnTheMarket = 'OnTheMarket',
  PendingOffer = 'PendingOffer',
  ArrangingTenancy = 'ArrangingTenancy',
  ActiveTenancy = 'ActiveTenancy',
  Completed = 'Completed',
  ActiveTenancyWithRenewal = 'ActiveTenancyWithRenewal',
  EndOfTenancyConfirmed = 'EndOfTenancyConfirmed',
  Invalid = 'Invalid',
}

export type ActiveStep = {
  number: number;
  name: VendorStepCodes | LandlordStepCodes;
  title: string;
  copy: string;
};

export type PropertyHeaderStepsConfig = {
  activeStep: ActiveStep;
  steps: ProgressIndicatorStep[];
};
export interface ViewHeaderProps {
  property: Property;
  marketingPortals?: MarketingPortals[];
  statusTags?: TagProps[];
}
